import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import { BTooltip, BTable } from "bootstrap-vue";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload";

let fields = [
  {
    key: "id",
    label: "شناسه",
  },
  {
    key: "mobile",
    label: "شماره تماس",
  },
  {
    key: "source",
    label: "منبع",
  },
  {
    key: "created_at",
    label: "تاریخ ثبت",
  },
  {
    key: "delete",
    label: "حذف",
  },
];

// let fields = [
//   {
//     key: "id",
//     label: "شناسه",
//   },
// ]
// {
//   fields.push({
//     key: "mobile",
//     label: "شماره تماس",
//   },)
// }
// fields = [...fields, ...[  {
//   key: "created_at",
//   label: "تاریخ ثبت",
// },
//   {
//     key: 'delete',
//     label: 'حذف'
//   },]]

export default {
  name: "offers",
  components: {
    Loading,
    BTooltip,
    BTable,
    ExcelDownload,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/newsletter/amazing",
      fields: fields,
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {},
  methods: {},
};
