import {getStatusLabel} from '@@/core/libraries/adminFunctions'
import Loading from '@@/core/components/shared/Loading/Loading'
import OrderStatus from '@@/core/components/order/OrderStatus/OrderStatus'
import OrderStatusesView from "@@/core/components/report/OrderStatusesView";
import mixins from "@@/core/mixins/mixins";
import WalletSvg from "@@/core/components/svgs/WalletSvg";

import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BModal,
  VBModal, VBToggle
} from "bootstrap-vue";

export default {
  name: "order",
  components: {
    OrderStatusesView,
    Loading,
    OrderStatus, WalletSvg,
    BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput, BFormCheckbox, BModal
  },
  directives: {
    'b-modal': VBModal, 'b-toggle': VBToggle
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/orders",
      allSelected: false,
      widgets: [],
      btnStatus: false,
      selectedOrders: Array(100).fill(false),
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "customer",
          label: "مشتری",
        },
        {
          key: "receiver",
          label: "گیرنده",
        },
        {
          key: "order_items_unique_count",
          label: "تعداد آیتم",
        },
        {
          key: 'total',
          label: 'مبلغ کل'
        },
        {
          key: "tracking_code",
          label: "شماره پیگیری",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "detail",
          label: "جزئیات",
        },
      ],
      order: {},
      filter: {
        id:'',
        tracking_code: '',
        city: '',
        province: '',
        product_id: "",
        variety_id: "",
        status: "",
        customer_id: "",
        start_date: "",
        end_date: "",
        type:"",
      },
      typeFilterOptions: [
        {
          value: "kamva",
          title: 'کاموا',
        },
      ],

      statusFilterOptions: [
        {
          value: "delivered",
          title: getStatusLabel("delivered"),
        },
        {
          value: "in_progress",
          title: getStatusLabel("in_progress"),
        },
        {
          value: "new",
          title: getStatusLabel("new"),
        },
        {
          value: "reserved",
          title: getStatusLabel("reserved"),
        },
        {
          value: "wait_for_payment",
          title: getStatusLabel("wait_for_payment"),
        },
        {
          value: "canceled",
          title: getStatusLabel("canceled"),
        },
        {
          value: "failed",
          title: getStatusLabel("failed"),
        },
        {
          value: "returned",
          title: getStatusLabel("returned"),
        },
      ],
      statusOptions: [
        {
          value: "in_progress",
          title: getStatusLabel("in_progress"),
        },
        {
          value: "delivered",
          title: getStatusLabel("delivered"),
        },
        {
          value: "new",
          title: getStatusLabel("new"),
        },
      ],
      ordersStatus: null,
      orderStatusesInfo: null,
      customers: [],
      products: [],
      showingTrackings: []
    };
  },
  created() {
    if (this.$route.query.search) {
      let search = JSON.parse(this.$route.query.search)
      this.filter.tracking_code = search.tracking_code || '';
      this.filter.city = search.city || '';
      this.filter.province = search.province || '';
      this.filter.product_id = search.product_id || '';
      this.filter.variety_id = search.variety_id || '';
    }
  },
  mounted() {
    if (this.hasPermission("read_customer")) {
      // this.widgets = ["list_products", "list_customers"];
      this.widgets = ["list_customers"];
    } else {
      this.widgets = ["list_products"];
    }

    this.loadItems().then(res => {
      this.orderStatusesInfo = res.data.data.order_statuses
    });
  },
  watch: {
    selectedOrders(newVal) {
      let selectedOrders = newVal.filter((item) => item != false);
      if (selectedOrders != "") {
        this.btnStatus = true;
      } else {
        this.btnStatus = false;
      }
    },
    widgetData(newVal) {
      this.customers = newVal.customers;
      this.products = newVal.products;
    },
    products(newVal) {
      if (!newVal) {
        return
      }
      let products = newVal.forEach((product) => {
        product.title = 'شناسه : ' + product.id + ' - ' + product.title
        return product;
      });
      return products;
    },
    customers(newVal) {
      let customers = newVal.forEach((item) => {
        item.full_name =
            (item.first_name ? item.first_name : '') + " " + (item.last_name ? item.last_name : '') + " " + item.mobile;
        return item;
      });
      return customers;
    },
    'filter.status': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.status = this.statusFilterOptions.find(item => {
          return item.value == newVal
        })
      }
    },
    "filter.customer_id": function(newVal) {
      if (typeof newVal === "string" || typeof newVal === "number") {
        this.filter.customer_id = this.customers.find((item) => {
          return item.id == newVal;
        });
      }
    }
  },
  methods: {
    getStatusLabel,
    statusChangedByButton() {
      this.$nextTick(() => {
        this.filterItem()
      })
    },
    resetFilter() {
      this.$refs.productSearch.reset();
      this.filter = this.$options.data.apply(this).filter
    },
    selectProduct(p) {
      this.filter.product_id = p ? p.id : null
      this.filter.variety_id = null
    },
    selectVariety(v) {
      this.filter.variety_id = v ? v.id : null
    },
    showTrack(p) {
      if (!this.showingTrackings.includes(p)) {
        this.showingTrackings.push(p)
      }
    },
    hideTrack(p) {
      this.showingTrackings = this.showingTrackings.filter(_p => {
        return _p !== p;
      })
    },
    getReceiverName(order) {
      let address;
      try {
        address = JSON.parse(order.address)
      } catch (e) {
        return 'نامشخص'
      }
      return address.first_name + ' ' + address.last_name
    },
    orderSelect(index, id) {
      if (!this.selectedOrders[index]) {
        this.$set(this.selectedOrders, index, id);
      } else {
        this.$set(this.selectedOrders, index, false);
      }
    },
    orderSelectAll() {
      if (this.allSelected) {
        document
            .querySelectorAll("input[notselected]")
            .forEach((e) => e.click());
      } else {
        document.querySelectorAll("input[selected]").forEach((e) => e.click());
      }
    },
    async filterItem(page = 1) {
      this.searchParams = {
        tracking_code: this.filter.tracking_code,
        city: this.filter.city,
        province: this.filter.province,
        search1: this.filter.id ,
        searchBy1: "id",
        search2: this.filter.customer_id ? this.filter.customer_id.id : "",
        searchBy2: "customer_id",
        search3: this.filter.status ? this.filter.status.value : "",
        searchBy3: "status",
        searchType: this.filter.type ? this.filter.type.value : "",
        product_id: this.filter.product_id,
        variety_id: this.filter.variety_id,
        start_date: this.filter.start_date
            ? window.moment(this.filter.start_date, "YYYY.MM.DD").unix()
            : "",
        end_date: this.filter.end_date
            ? window.moment(this.filter.end_date, "YYYY.MM.DD").unix()
            : "",
      };
    },
    newOrder() {
      this.$root.$emit("bv::show::modal", "order-modal");
    },
    printOrders() {
      let selectedOrders = this.selectedOrders.filter((item) => item != false);
      selectedOrders = selectedOrders.join(",");
      let routeData = this.$router.resolve({
        path: "/admin/print-orders",
        query: { ids: selectedOrders },
      });
      window.open(routeData.href, "_blank");
    },
    async updateStatus() {
      this.disabled = true;
      let selectedOrders = this.selectedOrders.filter((item) => item != false);
      try {
        const response = await this.$axios.post("admin/orders/status/changes", {
          status: this.ordersStatus ? this.ordersStatus.value : "",
          ids: selectedOrders,
        });
        this.selectedOrders.forEach((order, index) => {
          if (!order) {
            return;
          }
          this.items.data[index].status = this.ordersStatus.value;
          order = false;
        });
        document.querySelectorAll("input[selected]").forEach((e) => e.click());
        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "change-status");
      } catch (error) {
        this.$root.notify(error);
      }

      this.disabled = false;
    },
    getSumWalletInvoices(walletInvoices) {
      return walletInvoices.reduce((a,b) => (a + (b.type === 'wallet' ? b.amount : b.wallet_amount)), 0)
    }
  },
};
