<template>
  <main class="mt-5 shipping">
    <ShippingRangeCreate
      @create="onCreate"
      @edit="onEdit"
      :edit="edit"
      :mode="mode"
    />
    <span id="add-btn">
      <button
        :disabled="hasPermission('write_shipping') ? false : true"
        @click="newShippingRange"
        v-b-modal.modal-ShippingRanges
        class="btn btn-success"
      >
        بازه جدید
      </button>
    </span>
    <b-tooltip
      v-if="!hasPermission('write_shipping')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">بازه ها</h5>
      </div>
      <div class="card-body">
        <b-table responsive hover bordered :items="items.data" :fields="fields">
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(status)="data">
            <span
              v-if="data.item.status == 1"
              class="badge badge-success status"
              >فعال</span
            >
            <span
              v-else-if="data.item.status == 0"
              class="badge badge-danger status"
              >غیرفعال</span
            >
          </template>
          <template v-slot:cell(edit)="data">
            <span :id="'edit-btn-' + data.item.id">
              <a
                :disabled="!hasPermission('modify_shipping') ? true : false"
                class="action-btns1  pt-1 px-2"
                @click="editItem(data.index)"
              >
                <i class="feather feather-edit  text-primary"></i>
              </a>
            </span>
            <b-tooltip
              v-if="!hasPermission('modify_shipping')"
              :target="'edit-btn-' + data.item.id"
              triggers="hover"
            >
              شما به این بخش دسترسی ندارید
            </b-tooltip>
          </template>
          <template v-slot:cell(delete)="data">
            <span :id="'delete-btn-' + data.item.id">
              <button
                :disabled="!hasPermission('delete_shipping') ? true : false"
                class="action-btns1  pt-1 px-2 "
                @click="deleteItem(data.item.id)"
              >
                <i class="feather feather-trash-2 text-danger"></i>
              </button>
            </span>
            <b-tooltip
              v-if="!hasPermission('delete_shipping')"
              :target="'delete-btn-' + data.item.id"
              triggers="hover"
            >
              شما به این بخش دسترسی ندارید
            </b-tooltip>
          </template>
        </b-table>
        <Loading v-if="!items || !items.data" />
      </div>
    </div>
  </main>
</template>

<script>
import { BTooltip, VBModal, BTable } from "bootstrap-vue";
import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import ShippingRangeCreate from "@@/core/components/shipping/ShippingRangeCreate.vue";
import { priceFilter } from "@@/core/libraries/filters";

function makeEdit() {
  return {
    lower: "",
    higher: "",
    amount: "",
  };
}

export default {
  name: "ShippingRanges",
  data() {
    return {
      url: "admin/shipping_ranges",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "lower",
          label: "پایین",
          formatter: priceFilter,
        },
        {
          key: "higher",
          label: "بالا",
          formatter: priceFilter,
        },
        {
          key: "amount",
          label: "مبلغ",
          formatter: priceFilter,
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      mode: "create",
      edit: makeEdit(),
    };
  },
  directives: {
    "b-modal": VBModal,
  },
  components: {
    ShippingRangeCreate,
    Loading,
    BTooltip,
    BTable,
  },
  mixins: [mixins],
  mounted() {
    this.url = this.url + `/${this.$route.params.id}`
    this.loadItems();
    this.url = 'admin/shipping_ranges'
  },
  methods: {
    newShippingRange() {
      this.mode = "create";
      this.edit = makeEdit();
    },
  },
};
</script>

<style scoped></style>
