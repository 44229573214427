<template>
    <main class="mt-5 shipping" style="min-height: 500px;">
      <div class="card mt-3">
        <div class="card-header  border-0">
          <h5 class="card-title font-bold"> حمل و نقل - شهرها </h5>
          <div style="flex:1"></div>
          <Back/>
        </div>
        <div class="card-body">
          <form ref="form" @submit.prevent="submit">
            <b-table responsive hover bordered :items="provinces" :fields="fields">
              <template v-slot:cell(province)="data">
                {{ data.item.name }}
              </template>
              <template v-slot:cell(cities)="data">
                <multiselect v-model="data.item.selected_cities" placeholder="انتخاب کنید"
                             open-direction="bottom" :options="data.item.cities" label="name" track-by="id"
                             :searchable="true" :multiple="true" :close-on-select="false" :show-labels="false"
                             :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
  
              </template>
              <template v-slot:cell(price)="data">
                <b-button variant="info" @click="citiesPrice(data.item.selected_cities)"
                          :disabled="data.item.selected_cities == null || data.item.selected_cities == '' ? true : false">
                  هزینه ارسال
                </b-button>
              </template>
            </b-table>
            <Loading v-if="provinces == null"/>
            <div class="alert alert-danger text-right" v-if="provinces == ''">
              <span class="text-right">موردی یافت نشد ! </span>
            </div>
            <div class="text-center">
              <button class="btn btn-success" type="submit">اعمال تغییرات</button>
            </div>
          </form>
        </div>
      </div>
      <b-modal id="cities-modal" title="هزینه ارسال شهرها" hide-footer>
        <b-table responsive class="" v-if="selected_cities != ''" hover bordered :items="selected_cities"
                 :fields="cities_fields">
          <template v-slot:cell(price)="data">
            <b-form-input :value="data.item.pivot.price | priceinput"
                          @input="value=>{data.item.pivot.price = value !== '' ? +value.replace(/\D/g, '') : ''}"></b-form-input>
          </template>
        </b-table>
        <div class="text-center">
          <b-button variant="success" @click="$root.$emit('bv::hide::modal', 'cities-modal')">
            ثبت
          </b-button>
        </div>
      </b-modal>
    </main>
  </template>
  <script>
  import ShippingCitiesImpl from '@@/core/pages/shipping/ShippingCities/ShippingCitiesImpl'
  
  export default {
    extends: ShippingCitiesImpl
  }
  </script>
  