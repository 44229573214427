export function getStatusLabel(status) {
    switch(status) {
        case 'new':
        return 'در انتظار تکمیل'
        case 'in_progress':
        return 'در حال پردازش '
        case 'in_examination':
        return 'در حال بررسی '
        case 'wait_for_payment':
        return 'در انتظار پرداخت'
        case 'delivered':
        return 'ارسال شده'
        case 'canceled':
        return 'کنسل شده'
        case 'failed':
        return 'خطا'
        case 'reserved':
        return 'رزرو شده'
        case 'returned':
        return 'مرجوعی'
    }
}

export function getStatusColor(name) {
    switch (name) {
        case 'canceled':
            return '#c21717'
        case 'new':
            return '#36f';
        case 'wait_for_payment':
            return '#f7bf0d'
        case 'delivered':
            return '#0dcd94'
        case 'in_progress':
            return '#9c9c9c';
        case 'failed':
            return '#c21717';
        case 'reserved':
            return '#a211f5';
        case 'returned':
            return '#000000';
    }
}

export function getEventLabel(event) {
    switch (event) {
        case 'created':
            return 'ایجاد'
        case 'updated':
            return 'ویرایش'
        case 'deleted':
            return 'حذف'
    }

    return '';
}

export function getModelLabel(model) {
    switch (true) {
        case model.includes('OrderItem'):
            return 'آیتم سفارش'
        case model.includes('Order'):
            return 'سفارش'
        case model.includes('Invoice'):
            return 'فاکتور'
        case model.includes('Product'):
            return 'محصول'
        case model.includes('Post'):
            return 'مطلب'
        case model.includes('Category'):
            return 'دسته بندی محصول'
        case model.includes('Coupon'):
            return 'کد تخفیف'
        case model.includes('Customer'):
            return 'مشتری'
        case model.includes('Deposit'):
            return 'شارژ کیف پول'
        case model.includes('Flash'):
            return 'کمپین'
        case model.includes('ProductComment'):
            return 'نظر محصول'
        case model.includes('Shipping'):
            return 'روش ارسال'
    }
}
// export function _getDefaultComponent(res){
//     return new Promise((resolve, reject) => {
//         import(
//             /* webpackChunkName: "[request]" */
//             `@@/core/${res}`
//             )
//             .then((component) => {
//                 resolve(component);
//             })
//             .catch((error) => {
//                 reject(error);
//             });
//     })
// }
// export function _getComponent(res){
//     return new Promise((resolve, reject) => {
//         import(
//             /* webpackChunkName: "[request]" */
//             `@/parts/admin/${res}`
//             )
//             .then((component) => {
//                 resolve(component);
//             })
//             .catch((error) => {
//                 _getDefaultComponent(res)
//                     .then((component) => {
//                         resolve(component);
//                     })
//                     .catch((error) => {
//                         // console.error(No Component with the name ${res}, error:, error);
//                         reject(error);
//                     });
//             });
//         });
//     }
