<template>
  <b-modal
    @ok.prevent="submit"
    :title="mode == 'edit' ? 'ویرایش بازه' : 'بازه جدید'"
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    id="modal-ShippingRanges"
    ok-title="ثبت"
    cancel-title="بستن"
  >
    <form ref="form" @submit.prevent="submit">
      <b-form-group>
        <label>از <span class="text-danger">*</span></label>
        <b-form-input
          :value="edit.lower | priceinput"
          name="lower"
          @input="
            (value) => {
              edit.lower = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        />
      </b-form-group>
      <b-form-group>
        <label>تا <span class="text-danger">*</span></label>
        <b-form-input
          :value="edit.higher | priceinput"
          name="higher"
          @input="
            (value) => {
              edit.higher = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        />
      </b-form-group>
      <b-form-group>
        <label>مبلغ <span class="text-danger">*</span></label>
        <b-form-input
          :value="edit.amount | priceinput"
          name="amount"
          @input="
            (value) => {
              edit.amount = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        />
      </b-form-group>
      <button type="submit" style="display: none"></button>
    </form>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from "bootstrap-vue";

export default {
  name: "ShippingRangeCreate",
  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      disabled: false,
    };
  },
  props: {
    mode: String,
    edit: Object,
  },
  methods: {
    async submit() {
      this.disabled = true;
      try {
        const url =
          this.mode == "edit"
            ? `admin/shipping_ranges/${this.edit.id}`
            : `admin/shipping_ranges`;
        let formData = new FormData(this.$refs.form);
        formData.set("lower", this.edit.lower);
        formData.set("higher", this.edit.higher);
        formData.set("amount", this.edit.amount);
        this.mode == "edit" ? formData.append("_method", "PUT") : "";
        formData.append("shipping_id", this.$route.params.id);

        const response = await this.$axios.post(url, formData);

        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "modal-ShippingRanges");

        if (this.mode === "edit") {
          this.$emit("edit", response.data.data, this.edit._index);
        } else {
          this.$emit("create", response.data);
        }
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>

<style scoped></style>
