import { ValidationObserver, ValidationProvider } from "vee-validate";
import adminAssets from "@@/core/mixins/adminAssets";

export default {
  name: "Login",
  mixins: [],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      sendingRequest: false,
    };
  },
  mounted() {
    if (this.$root.getCookie("usernameLogin")) {
      console.log({
        fromQuery: true,
        username: this.$root.getCookie("usernameLogin"),
        password: this.$root.getCookie("passwordLogin"),
      });
      this.submitLogin({
        fromQuery: true,
        username: this.$root.getCookie("usernameLogin"),
        password: this.$root.getCookie("passwordLogin"),
      });
    }
  },
  methods: {
    async submitLogin(e) {
      console.log(e);
      let formData = null;
      try {
        this.sendingRequest = true;
        if (e.fromQuery) {
          formData = new FormData();
          formData.append("username", e.username);
          formData.append("password", e.password);
        } else {
          formData = new FormData(e.target);
        }
        const response = await this.$axios.post("/admin/login", formData);
        this.$root.setCookie(
          "adminAuthorization",
          `Bearer ${response.data.data.token}`
        );
        this.$axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.data.token}`;
        this.$root.notify(response.data.message, "success");
        if (e.fromQuery) {
          this.$root.deleteCookie("usernameLogin");
          this.$root.deleteCookie("passwordLogin");
          this.$router.push("/admin/order");
        } else {
          this.$router.push("/admin");
        }
      } catch (error) {
        console.error(error);
        this.$root.deleteCookie("usernameLogin");
          this.$root.deleteCookie("passwordLogin");
        this.$root.notify(error);
      } finally {
        this.sendingRequest = false;
      }
    },
  },
};
